const services = [
  {
    id: 1,
    image:
      "https://blog.focuslabel.com/hs-fs/hubfs/What%20Is%20Image%20Transfer%20In%20Flexo%20Printing-1.jpg?width=750&name=What%20Is%20Image%20Transfer%20In%20Flexo%20Printing-1.jpg",
    title: "Flexographic Printing",
    description:
      "Flexography utilizes a flexible printing plate to transfer ink to printable substrates in a high-speed rotary process. Flexography utilizes a flexible printing plate to transfer ink to printable substrates in a high-speed rotary process. Flexography utilizes a flexible printing plate to transfer ink to printable substrates in a high-speed rotary process.",
  },
  {
    id: 2,
    image:
      "https://www.starlabel.com/wp-content/uploads/Tactile-Varnish-Square-1.jpg",
    title: "Embellishment Techniques",
    description:
      "Our decorative embellishment techniques, including a variety of inks, coatings, die cuts, embossing and debossing, and speciality foils, are added after the printing process is complete, in the post-press phase. With top-notch digital printing technology, we are here to give your label designs a classy and finished look.",
  },
  {
    id: 3,
    image:
      "https://advertisersprinting.com/wp-content/uploads/2021/03/nature-3289812_1280.jpg",
    title: "Environmentally Friendly and Sustainable Solutions",
    description:
      "Protecting the environment through sustainability is important to us at Star Label, and we have been taking steps to make our processes, and by extension your labels, more environmentally friendly. Flexography utilizes a flexible printing plate to transfer ink to printable substrates in a high-speed rotary process.",
  },
  {
    id: 4,
    image:
      "https://sbedirect.com/img/leoblog/b/1/83/lg-b-tout-savoir-sur-la-numerotation-sequentielle.png",
    title: "Sequential Numbering & Bar Coding",
    description:
      "Whether you want to label stocks, employee badges or other inventory; our Sequential Numbering and Barcoding techniques offer you a wide range of barcode types and consecutive numbering configurations customized to your business needs.",
  },
];

export const getAllServices = () => {
  return services;
};
