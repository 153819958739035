import React from "react";

const Modal = ({ isVisible, data, closeModal }) => {
  if (!isVisible) return null;

  return (
    <>
      <div className="modal-grey-area" onClick={closeModal}></div>
      <div className="modal-visible">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body d-flex">
              <div className="modal-img">
                <img src={data.image} alt={data.title} />
              </div>
              <div className="modal-content-body">
                <button
                  type="button"
                  className="close-button"
                  onClick={closeModal}
                >
                  &times;
                </button>
                <div className="modal-text-content">
                  <h3>{data.title}</h3>
                  <hr />
                  <div className="modal-description">
                    <p>{data.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
