import React, { useState } from "react";
import { getAllFAQs } from "../data/faq"; // Import the FAQ data service

const Faq = () => {
  const [activeFaqId, setActiveFaqId] = useState(null); // State to track the active FAQ item
  const [showMore, setShowMore] = useState(false); // State to toggle the visibility of more FAQs
  const faqs = getAllFAQs(); // Fetch all FAQs
  const visibleFaqs = showMore ? faqs : faqs.slice(0, 5); // Determine which FAQs to display

  const toggleFaq = (id) => {
    setActiveFaqId(activeFaqId === id ? null : id); // Toggle active FAQ item
  };

  const toggleShowMore = () => {
    setShowMore(!showMore); // Toggle show more FAQs
  };

  return (
    <div>
      <section id="faq" className="faq section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Frequently Asked Questions</h2>
          <p>Labeling Excellence, Print Precision</p>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
              <div className="faq-container">
                {visibleFaqs.map((faq) => (
                  <div
                    className={`faq-item ${
                      activeFaqId === faq.id ? "faq-active" : ""
                    }`}
                    key={faq.id}
                  >
                    <h3 onClick={() => toggleFaq(faq.id)}>
                      {faq.question}
                      <i
                        className={`faq-toggle bi ${
                          activeFaqId === faq.id
                            ? "bi-chevron-left"
                            : "bi-chevron-right"
                        }`}
                      ></i>
                    </h3>
                    {activeFaqId === faq.id && (
                      <div className="faq-content">
                        <p>{faq.answer}</p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
              <div className="text-center">
                <button
                  className="btn-get-started mt-4"
                  onClick={toggleShowMore}
                >
                  {showMore ? "Show Less" : "Show More"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Faq;
