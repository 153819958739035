import React, { useState } from "react";
import { db, collection, addDoc } from "../config/firebase";

const Contact = ({ contactRef }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    timeStamp: Date.now(),
  });
  const [formSubmitted, setFormSubmitted] = useState(false); // State to track if the form is submitted
  const [isSubmitting, setIsSubmitting] = useState(false); // State to track if the form is being submitted

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true

    // Save data to Firestore
    try {
      await addDoc(collection(db, "contacts"), formData);
      // Send email via server
      await fetch("https://prolabels-backend.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify(formData),
      });
      setFormSubmitted(true); // Set form submitted state to true
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });
    } catch (error) {
      alert("Error sending message");
    } finally {
      setIsSubmitting(false); // Set submitting state to false
    }
  };

  return (
    <div ref={contactRef}>
      <section id="contact" className="contact section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Contact</h2>
          <p>Labeling Excellence, Print Precision</p>
        </div>

        <div className="container" data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-12">
            {formSubmitted ? (
              <div className="sent-message text-center">
                <h4>
                  Your response has been submitted. Our team will contact you
                  shortly.
                </h4>
              </div>
            ) : (
              <form
                className="php-email-form"
                data-aos="fade-up"
                data-aos-delay="500"
                onSubmit={handleSubmit}
              >
                <div className="row gy-4">
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      value={formData.name}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="col-md-12">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="4"
                      placeholder="Message"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <div className="col-md-12 text-center">
                    <div className="error-message"></div>
                    <button type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <span>
                          <i className="bi bi-arrow-repeat spinner"></i>{" "}
                          Submitting...
                        </span>
                      ) : (
                        "Send Message"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
          <br />
          <div className="row gy-4">
            <div className="col-lg-6">
              <div className="row gy-4">
                <div className="col-lg-12">
                  <div
                    className="info-item d-flex flex-column justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <i className="bi bi-geo-alt"></i>
                    <h3>Address</h3>
                    <p>
                      D-40, Hosiery Complex, Phase 2, Noida, Uttar Pradesh.
                      201305
                    </p>
                  </div>
                </div>

                <div className="col-md-6">
                  <div
                    className="info-item d-flex flex-column justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <i className="bi bi-telephone"></i>
                    <h3>Call Us</h3>
                    <p>
                      Ayush Bhatia :- <a href="tel:+919717017112">9717017112</a>
                    </p>
                    <p>
                      Parth Chhabra :-{" "}
                      <a href="tel:+919626304111">9626304111</a>
                    </p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div
                    className="info-item d-flex flex-column justify-content-center align-items-center"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <i className="bi bi-envelope"></i>
                    <h3>Email Us</h3>
                    <p>
                      <a href="mailto:info@prolabels.in">info@prolabels.in</a>
                    </p>
                    <p>
                      <p className="text-white">info@prolabels.in</p>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 overflow-hidden">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14021.05278453682!2d77.4144814!3d28.5318077!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ceff2a36103ad%3A0x6445fcac8fab597e!2sPro%20Labels%20Private%20Limited!5e0!3m2!1sen!2sin!4v1724517532759!5m2!1sen!2sin"
                width="640"
                height="370"
                style={{ border: "0" }}
                allowFullScreen=""
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
