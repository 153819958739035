import React, { useRef } from "react";
import Header from "./Header";
import AboutUs from "./AboutUs";
import Services from "./Services";
import Hero from "./Hero";
import Stats from "./Stats";
import Portfolio from "./Portfolio";
import Faq from "./Faq";
import Contact from "./Contact";
import Footer from "./Footer";
import Clients from "./Clients";
import Features from "./Features";
import Cta from "./Cta";

const Home = () => {
  const aboutRef = useRef(null);
  const mainRef = useRef(null);
  const serviceRef = useRef(null);
  const portfolioRef = useRef(null);
  const contactRef = useRef(null);
  return (
    <div>
      <Header
        aboutRef={aboutRef}
        mainRef={mainRef}
        serviceRef={serviceRef}
        contactRef={contactRef}
        portfolioRef={portfolioRef}
      />
      <Hero aboutRef={aboutRef} />
      <Clients />
      <AboutUs aboutRef={aboutRef} />
      <Stats />
      <Services serviceRef={serviceRef} />
      <Features />
      <Portfolio portfolioRef={portfolioRef} />
      <Cta contactRef={contactRef} />
      <Faq />
      <Contact contactRef={contactRef} />
      <Footer
        aboutRef={aboutRef}
        mainRef={mainRef}
        serviceRef={serviceRef}
        contactRef={contactRef}
        portfolioRef={portfolioRef}
      />
    </div>
  );
};

export default Home;
