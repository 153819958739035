// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, addDoc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCSG8wTyiM7cUlXFZrdV9dekNfrmsCJKf8",
  authDomain: "pro-labels-1cc20.firebaseapp.com",
  projectId: "pro-labels-1cc20",
  storageBucket: "pro-labels-1cc20.appspot.com",
  messagingSenderId: "763497370466",
  appId: "1:763497370466:web:ebac9387d7ed059778123b",
  measurementId: "G-GFEEZH5F11",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);

export { db, collection, addDoc };
