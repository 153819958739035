import React, { useState, useEffect } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { getAllClients } from "../data/clients"; // Import the data service

const Clients = () => {
  const [maxImagesPerSlide, setMaxImagesPerSlide] = useState(5);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    // Fetch client data on component mount
    const clientData = getAllClients();
    setClients(clientData);

    // Update the maximum number of images per slide based on window width
    const updateMaxImagesPerSlide = () => {
      const width = window.innerWidth;
      if (width >= 1200) {
        setMaxImagesPerSlide(5);
      } else if (width >= 992) {
        setMaxImagesPerSlide(4);
      } else if (width >= 768) {
        setMaxImagesPerSlide(3);
      } else {
        setMaxImagesPerSlide(2);
      }
    };

    updateMaxImagesPerSlide();
    window.addEventListener("resize", updateMaxImagesPerSlide);

    return () => {
      window.removeEventListener("resize", updateMaxImagesPerSlide);
    };
  }, []);

  // Group the client images based on the number of images per slide
  const groupedClients = [];
  for (let i = 0; i < clients.length; i += maxImagesPerSlide) {
    groupedClients.push(clients.slice(i, i + maxImagesPerSlide));
  }

  return (
    <div id="clients" className="clients">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="swiper init-swiper">
          <div className="swiper-wrapper align-items-center">
            <Carousel autoPlay showThumbs={false}>
              {groupedClients.map((group, index) => (
                <div className="swiper-slide" key={index}>
                  <div className="image-group">
                    {group.map((client, i) => (
                      <img
                        key={client.id}
                        src={client.image}
                        className="img-fluid carousel-image"
                        alt={`Client ${client.id}`}
                      />
                    ))}
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Clients;
