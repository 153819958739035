import React from "react";

const Hero = ({ aboutRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <section id="hero" class="hero section">
        <img
          // src="https://www.qodenext.com/blog/wp-content/uploads/2023/07/Label-Printing-Machine-for-Small-business-compressed.jpg"
          src="https://www.prakashlabels.com/wp-content/uploads/2019/07/Banner-image.jpg"
          alt=""
          data-aos="fade-in"
        />
        {/* <img src="assets/img/hero-bg.jpg" alt="" data-aos="fade-in" /> */}

        <div class="container">
          <div class="row">
            <div class="text-center">
              <h2 data-aos="fade-up" data-aos-delay="100">
                Grow business with Pro Labels
              </h2>
              <p data-aos="fade-up" data-aos-delay="200">
                We are one stop integrated manufacturer of pressure sensitive,
                self adhesive and wet-glue labels
              </p>
              <div class="mt-4" data-aos="fade-up" data-aos-delay="300">
                <a
                  onClick={() => {
                    handleScroll(aboutRef.current);
                  }}
                  class="btn-get-started"
                >
                  Get Started
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Hero;
