const clients = [
  {
    id: 1,
    image: "assets/img/clients/c1.png",
  },
  {
    id: 2,
    image: "assets/img/clients/c2.png",
  },
  {
    id: 3,
    image: "assets/img/clients/c3.png",
  },
  {
    id: 4,
    image: "assets/img/clients/c4.png",
  },
  {
    id: 5,
    image: "assets/img/clients/c5.jpg",
  },
  {
    id: 6,
    image: "assets/img/clients/c6.jpg",
  },
  // {
  //   id: 7,
  //   image: "assets/img/clients/c7.png",
  // },
  // {
  //   id: 8,
  //   image: "assets/img/clients/c8.jpeg",
  // },
  // {
  //   id: 9,
  //   image: "assets/img/clients/c9.png",
  // },
  {
    id: 10,
    image: "assets/img/clients/c10.png",
  },
  // {
  //   id: 11,
  //   image: "assets/img/clients/c11.png",
  // },
  {
    id: 12,
    image: "assets/img/clients/c12.png",
  },
  {
    id: 13,
    image: "assets/img/clients/c16.png",
  },
  // {
  //   id: 14,
  //   image: "assets/img/clients/c5.png",
  // },
  // {
  //   id: 15,
  //   image: "assets/img/clients/c15.png",
  // },
];

export const getAllClients = () => {
  return clients;
};
