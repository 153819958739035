import React from "react";
import { getAllPortfolio } from "../data/portfolio"; // Import the portfolio data service

const Portfolio = ({ portfolioRef }) => {
  const portfolioItems = getAllPortfolio(); // Fetch all portfolio items

  return (
    <div ref={portfolioRef}>
      <section id="portfolio" className="portfolio section">
        <div className="container section-title" data-aos="fade-up">
          <h2>Portfolio</h2>
          <p>Labeling Excellence, Print Precision</p>
        </div>

        <div className="container">
          <div
            className="isotope-layout"
            data-default-filter="*"
            data-layout="masonry"
            data-sort="original-order"
          >
            <div
              className="row gy-4 isotope-container"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              {portfolioItems.map((item) => (
                <div
                  key={item.id}
                  className="col-lg-4 col-md-6 portfolio-item isotope-item filter-product"
                >
                  <div className="portfolio-content h-100">
                    <img
                      src={item.image}
                      className="img-fluid"
                      alt={item.title}
                    />
                    <div className="portfolio-info">
                      <h4>{item.title}</h4>
                      <p>{item.description}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Portfolio;
