import React from "react";

const Stats = () => {
  return (
    <div>
      <section id="stats" class="stats section">
        <div class="container" data-aos="fade-up" data-aos-delay="100">
          <div class="row gy-4">
            <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
              <i class="bi bi-emoji-smile"></i>
              <div class="stats-item">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="232"
                  data-purecounter-duration="1"
                  class="purecounter"
                >65</span>
                <p>Happy Clients</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
              <i class="bi bi-journal-richtext"></i>
              <div class="stats-item1">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="521"
                  data-purecounter-duration="1"
                  class="purecounter"
                >21</span>
                <p>Projects</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
              <i class="bi bi-headset"></i>
              <div class="stats-item">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="1463"
                  data-purecounter-duration="1"
                  class="purecounter"
                >9</span>
                <p>Experience</p>
              </div>
            </div>

            <div class="col-lg-3 col-md-6 d-flex flex-column align-items-center">
              <i class="bi bi-people"></i>
              <div class="stats-item1">
                <span
                  data-purecounter-start="0"
                  data-purecounter-end="15"
                  data-purecounter-duration="1"
                  class="purecounter"
                >2</span>
                <p>Awards</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Stats;
