import React from "react";

const Features = () => {
  return (
    <div>
      <section
        id="services"
        class="services section section-bg dark-background"
      >
        <div class="container section-title" data-aos="fade-up">
          <h2>Features</h2>
          <p>Labeling Excellence, Print Precision</p>
        </div>

        <div class="container">
          <div class="row gy-4">
            <div class="col-md-6" data-aos="fade-up" data-aos-delay="100">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-briefcase icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">QUALITY</a>
                  </h4>
                  <p class="description">
                    At Pro Labels, form and function go hand in hand. So you can
                    count on eye-catching, high quality labels that will
                    showcase your product and stand the test of time - every
                    time.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="200">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-card-checklist icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">EXPERT ADVICE</a>
                  </h4>
                  <p class="description">
                    With over 50 years of printing experience, our team of
                    experts are ready to tackle any project that you have. We
                    ask the right questions to get you the right solution.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="300">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-bar-chart icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">RAPID TURNAROUND</a>
                  </h4>
                  <p class="description">
                    Industry leading turnaround time. Every customer is treated
                    as a top priority at Pro. Expect your labels within 1-2
                    weeks, sometimes faster.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="400">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-binoculars icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">CUSTOMIZATION</a>
                  </h4>
                  <p class="description">
                    Stand out from the rest and tailor your labels to your exact
                    needs. We have the solutions. You make the impact.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="500">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-brightness-high icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">INNOVATIVE TECHNOLOGY</a>
                  </h4>
                  <p class="description">
                    Utilize the latest printing tech with 8-Colour Flexo presses
                    and in-house plate-making for precise, cutting-edge labels.
                  </p>
                </div>
              </div>
            </div>

            <div class="col-md-6" data-aos="fade-up" data-aos-delay="600">
              <div class="service-item d-flex position-relative h-100">
                <i class="bi bi-calendar4-week icon flex-shrink-0"></i>
                <div>
                  <h4 class="title">
                    <a class="stretched-link">SUSTAINABILITY</a>
                  </h4>
                  <p class="description">
                    Adopt eco-friendly practices with sustainable materials and
                    processes for high-quality labels that minimize
                    environmental impact.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Features;
