const portfolio = [
  {
    id: 1,
    image: "assets/img/portfolio/1.jpeg",
    title: "Tamper Evident Label",
    description:
      "Tamper evident labels have been in the market from long time and are here to stay as they provide a full proof security in packing of products. We provide these void labels in different finishes like white, silver and transparent transfers.",
  },
  {
    id: 2,
    image: "assets/img/portfolio/2.jpg",
    title: "Screen Effect Label",
    description:
      "Screen effect makes the label stand out, this raised effect helps in bringing another life to text matter and a real life texture effect on the objects.",
  },
  {
    id: 3,
    image: "assets/img/portfolio/3.jpg",
    title: "Booklet Labels",
    description:
      "If you have less space on the container but want more text matter then “Book Let Labels” are your savior. They are also known as expandable reseal labels, as the top layer can be opened and then again sealed by hand pressure.",
  },
  {
    id: 4,
    image: "assets/img/portfolio/4.jpg",
    title: "Crimp Through Label",
    description:
      "Team at Pro Labels is always looking for new innovations and enhancements and keen on bringing something new to the market and that’s how crimp through labels came. We have been providing these labels to the industry where label is up to the sealing point thus giving a premium look",
  },
  {
    id: 5,
    image: "assets/img/portfolio/5.jpg",
    title: "Bottle Labels",
    description:
      "We have been doing bottle labels in substrates such as polypropylene in all three forms as metallized, clear and white. With different finishes as matt, gloss and satin finishes. This is one of our labels which is in PP Met with effects as Screen and Laquer",
  },
  {
    id: 5,
    image: "assets/img/portfolio/6.jpg",
    title: "Metallic Foiling",
    description:
      "Hot and Cold Foil stamping to provide a glittery effect to the label and also make it stand out from the rest in market",
  },
];

export const getAllPortfolio = () => {
  return portfolio;
};
