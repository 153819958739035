import React from "react";

const Cta = ({ contactRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="text-center">
          <h3>Get Your Custom Labels Printed with Us</h3>
          <p>
            Reach out today to discuss your label printing needs. We're here to
            deliver excellence and precision in every label.
          </p>
          <button
            onClick={() => {
              handleScroll(contactRef.current);
            }}
            className="btn-get-started"
          >
            Contact Us
          </button>
        </div>
      </div>
    </section>
  );
};

export default Cta;
