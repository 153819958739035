import React from "react";

const Footer = () => {
  return (
    <div>
      <footer id="footer" class="footer dark-background">
        {/* <div class="container footer-top">
          <div class="row gy-4">
            <div class="col-lg-4 col-md-6 footer-about">
              <a href="index.html" class="logo d-flex align-items-center">
                <img src="assets/img/logo.png" alt="" />
              </a>
              <div class="footer-contact pt-3">
                <p>D-40, Hosiery Complex, Phase 2</p>
                <p>Noida, Uttar Pradesh. 201305</p>
                <p class="mt-3">
                  <strong>Phone:</strong>{" "}
                  <p>
                    Ayush Bhatia :- <a href="tel:+919717017112">9717017112</a>
                  </p>
                  <p>
                    Parth Chhabra :- <a href="tel:+919626304111">9626304111</a>
                  </p>
                </p>
                <p>
                  <strong>Email:</strong>{" "}
                  <span>
                    <a href="mailto:info@prolabels.in">info@prolabels.in</a>
                  </span>
                </p>
              </div>
              <div class="social-links d-flex mt-4">
                <a href="">
                  <i class="bi bi-twitter-x"></i>
                </a>
                <a href="">
                  <i class="bi bi-facebook"></i>
                </a>
                <a href="">
                  <i class="bi bi-instagram"></i>
                </a>
                <a href="">
                  <i class="bi bi-linkedin"></i>
                </a>
              </div>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Services</a>
                </li>
                <li>
                  <a href="#">Terms of service</a>
                </li>
                <li>
                  <a href="#">Privacy policy</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-2 col-md-3 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <a href="#">Web Design</a>
                </li>
                <li>
                  <a href="#">Web Development</a>
                </li>
                <li>
                  <a href="#">Product Management</a>
                </li>
                <li>
                  <a href="#">Marketing</a>
                </li>
                <li>
                  <a href="#">Graphic Design</a>
                </li>
              </ul>
            </div>

            <div class="col-lg-4 col-md-12 footer-newsletter">
              <h4>Our Newsletter</h4>
              <p>
                Subscribe to our newsletter and receive the latest news about
                our products and services!
              </p>
              <form
                action="forms/newsletter.php"
                method="post"
                class="php-email-form"
              >
                <div class="newsletter-form">
                  <input type="email" name="email" />
                  <input type="submit" value="Subscribe" />
                </div>
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">
                  Your subscription request has been sent. Thank you!
                </div>
              </form>
            </div>
          </div>
        </div> */}

        <div class="container copyright text-center mt-4">
          <p>
            © <span>Copyright 2024</span>{" "}
            <strong class="px-1 sitename">Pro Labels Pvt. Ltd.</strong>{" "}
            <span>All Rights Reserved.</span>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
