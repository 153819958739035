const faqs = [
    {
      id: 1,
      question: "What types of labels do you manufacture?",
      answer:
        "We manufacture a wide range of labels including pressure-sensitive, self-adhesive, and wet-glue labels. Our portfolio includes specialized labels such as tamper-evident labels, screen effect labels, booklet labels, crimp through labels, and bottle labels.",
    },
    {
      id: 2,
      question: "What printing technologies do you use?",
      answer:
        "We use advanced printing technologies, including an 8-color Gallus Flexo Printing Press. We also offer online foil stamping, UV/Aqua varnish, screen printing, and 8-color central impression letterpress for laminates (ABL/PBL).",
    },
    {
      id: 3,
      question: "Do you offer environmentally friendly label solutions?",
      answer:
        "Yes, we are committed to sustainability and offer environmentally friendly and sustainable label solutions. Our processes and materials are designed to reduce environmental impact without compromising quality.",
    },
    {
      id: 4,
      question: "What embellishment techniques do you provide?",
      answer:
        "We offer a variety of embellishment techniques to enhance the appearance of your labels. These include specialty inks, coatings, die cuts, embossing and debossing, and various types of foils. Our embellishment techniques add a premium touch to your label designs.",
    },
    {
      id: 5,
      question: "How do you ensure the quality of your labels?",
      answer:
        "Quality is paramount to us. We maintain 5S standards across our production floors and use BST defect detection systems, including camera inspection machines, to ensure the highest quality. We also perform GSM tests, scuff tests, tape tests, and dyne tests as part of our quality assurance process.",
    },
    {
      id: 6,
      question: "Can you handle custom label designs?",
      answer:
        "Absolutely. We work closely with our clients to develop custom label designs that meet their specific needs. Whether you require unique materials, finishes, or printing techniques, we have the expertise to bring your vision to life.",
    },
    {
      id: 7,
      question: "What substrates do you commonly work with?",
      answer:
        "We commonly work with a variety of substrates including polypropylene, polyethylene, chromo paper variants, and filmic label stock. Our diverse material options ensure that we can meet the demands of various industries.",
    },
    {
      id: 8,
      question: "Do you offer sequential numbering and barcoding services?",
      answer:
        "Yes, we offer sequential numbering and barcoding services. This includes a wide range of barcode types and consecutive numbering configurations, which can be customized to suit your business needs.",
    },
    {
      id: 9,
      question: "How do I place an order for labels?",
      answer:
        "You can place an order by contacting us directly through our website or by reaching out to our sales team. We’ll guide you through the process, from selecting the right materials and designs to final delivery.",
    },
    {
      id: 10,
      question: "What industries do you serve?",
      answer:
        "We serve a wide range of industries including pharmaceuticals, cosmetics, FMCG, and electronic consumables. Our expertise in label manufacturing allows us to cater to the unique requirements of each sector.",
    },
  ];

  // Function to get all FAQs
  export const getAllFAQs = () => {
    return faqs;
  };

  // Function to get a FAQ by id
  export const getFAQById = (id) => {
    return faqs.find((faq) => faq.id === id);
  };
