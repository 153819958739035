import React from "react";

const AboutUs = ({ aboutRef }) => {
  return (
    <div ref={aboutRef}>
      <section id="about" class="about section section-bg dark-background">
        <div class="container position-relative">
          <div class="row gy-5">
            <div
              class="content col-xl-4 d-flex flex-column px-2"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <h3 class="mt-4">{/* Our Journey */}</h3>
              <div class="mt-4 about-btn button-red align-self-center align-self-xl-start">
                <span>About us</span> <i class="bi bi-chevron-right"></i>
              </div>
              <p class="mt-4">
                Established nearly a decade ago, Pro Labels has grown into a
                trusted name across multiple verticals, including Pharma,
                Cosmetics, FMCG, and Electronic Consumables. Our success is
                deeply rooted in the trust and support of our clients, and we
                are eager to continue expanding and learning in the future.
              </p>
            </div>

            <div class="col-xl-8 px-5" data-aos="fade-up" data-aos-delay="200">
              <div class="row gy-4">
                <div class="col-md-6 icon-box position-relative">
                  <i class="bi bi-briefcase"></i>
                  <h4>
                    <a class="stretched-link">
                      Integrated Label Manufacturing
                    </a>
                  </h4>
                  <p>
                    We are a leading provider of pressure-sensitive,
                    self-adhesive, and wet-glue labels, catering to industries
                    like Pharma, Cosmetics, FMCG, and Electronics.
                  </p>
                </div>

                <div class="col-md-6 icon-box position-relative">
                  <i class="bi bi-gem"></i>
                  <h4>
                    <a class="stretched-link">
                      Advanced Printing Capabilities
                    </a>
                  </h4>
                  <p>
                    We use cutting-edge 8-Colour Gallus Flexo Printing and offer
                    features like Online Foil Stamping, UV/Aqua Varnish, and
                    Screen Printing.
                  </p>
                </div>

                <div class="col-md-6 icon-box position-relative">
                  <i class="bi bi-broadcast"></i>
                  <h4>
                    <a class="stretched-link">
                      Expertise in Materials
                    </a>
                  </h4>
                  <p>
                    Our work includes Polypropylene, Polyethylene, Chromo paper
                    variants, and Filmic Label Stock, supported by in-house
                    plate-making and BST Defect Detection systems.
                  </p>
                </div>

                <div class="col-md-6 icon-box position-relative">
                  <i class="bi bi-easel"></i>
                  <h4>
                    <a class="stretched-link">
                      Quality Commitment
                    </a>
                  </h4>
                  <p>
                    We adhere to the 5S standard and perform rigorous quality
                    tests, ensuring top-notch production in our air-conditioned,
                    dust-proof facilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
