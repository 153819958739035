import React from "react";

const header = ({
  mainRef,
  aboutRef,
  serviceRef,
  portfolioRef,
  contactRef,
}) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    const div = document.querySelector("#navbar");
    div.classList.add("navbar");
    div.classList.remove("navbar-mobile");
  };
  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div ref={mainRef}>
      <header id="header" class="header d-flex align-items-center sticky-top">
        <div class="container-fluid container-xl position-relative d-flex align-items-center">
          <a href="/" class="logo d-flex align-items-center me-auto">
            <img src="assets/img/logo.png" alt="" />
          </a>
          <nav id="navbar" className="navbar">
            <ul>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  onClick={() => {
                    handleScroll(mainRef.current);
                  }}
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  onClick={() => {
                    handleScroll(aboutRef.current);
                  }}
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  onClick={() => {
                    handleScroll(serviceRef.current);
                  }}
                >
                  Services
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  onClick={() => {
                    handleScroll(portfolioRef.current);
                  }}
                >
                  Portfolio
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  aria-current="page"
                  onClick={() => {
                    handleScroll(contactRef.current);
                  }}
                >
                  Contact
                </a>
              </li>
            </ul>
            {/* <button onClick={mobileNavView} className="mobile-nav-toggle">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-glyphs/30/FFFFFF/menu--v1.png"
                alt="menu--v1"
              />
            </button> */}
            <i
              onClick={mobileNavView}
              class="mobile-nav-toggle d-xl-none bi bi-list"
            ></i>
          </nav>

          <a
            class="btn-getstarted"
            onClick={() => {
              handleScroll(aboutRef.current);
            }}
          >
            Get Started
          </a>
        </div>
      </header>
    </div>
  );
};

export default header;
