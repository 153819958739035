import React from "react";
import ReactGA from "react-ga4";
import Home from "./components/Home";

const App = () => {
  ReactGA.initialize("G-Q3G09Q95BS");
  return (
    <div>
      <Home />
    </div>
  );
};

export default App;
