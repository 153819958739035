import React, { useState } from "react";
import Modal from "./Modal"; // Import the Modal component
import { getAllServices } from "../data/services"; // Import the data service

const Services = ({ serviceRef }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({
    title: "",
    description: "",
    image: "",
  });

  // Fetch the service data
  const services = getAllServices();

  const openModal = (title, description, image) => {
    setModalData({ title, description, image });
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const handleReadMoreClick = (e, title, description, image) => {
    e.preventDefault();
    openModal(title, description, image);
  };

  return (
    <div ref={serviceRef}>
      <section id="more-services" className="more-services">
        <div className="container">
          <div className="container section-title" data-aos="fade-up">
            <h2>Services</h2>
            <p>Labeling Excellence, Print Precision</p>
          </div>

          <div className="row">
            {services.map((service) => (
              <div
                key={service.id}
                className="col-md-6 d-flex align-items-stretch"
              >
                <div
                  className="card"
                  style={{ backgroundImage: `url(${service.image})` }}
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="card-body">
                    <h5 className="card-title">{service.title}</h5>
                    <p className="card-text">{service.description}</p>
                    {/* <div className="read-more">
                      <a
                        href="#"
                        onClick={(e) =>
                          handleReadMoreClick(
                            e,
                            service.title,
                            service.description,
                            service.image
                          )
                        }
                      >
                        <i className="bi bi-arrow-right"></i> Read More
                      </a>
                    </div> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Modal
        isVisible={isModalVisible}
        data={modalData}
        closeModal={closeModal}
      />
    </div>
  );
};

export default Services;
